<template>
    <el-select
        v-model="selectedType"
        :placeholder="$t('economy.all') + ' (' + invoiceNumbers.all +')'"
        filterable
        clearable
        class="w-40 mr-2"
        @change="selectType"
    >
        <el-option :label="'Transfer' + ' (' + invoiceNumbers.transfer +')'" value="transfer" class="w-48">
            <span style="float: left">Transfer</span>
            <span style="float: right; color: #8492a6; font-size: 13px">({{ invoiceNumbers.transfer }})</span>
        </el-option>
        <el-option :label="'Autogiro' + ' (' + invoiceNumbers.autogiro +')'" value="autogiro">
            <span style="float: left">Autogiro</span>
            <span style="float: right; color: #8492a6; font-size: 13px">({{ invoiceNumbers.autogiro }})</span>
        </el-option>
        <el-option :label="'E-faktura' + ' (' + invoiceNumbers.einvoice +')'" value="einvoice">
            <span style="float: left">E-faktura</span>
            <span style="float: right; color: #8492a6; font-size: 13px">({{ invoiceNumbers.einvoice }})</span>
        </el-option>
        <el-option :label="'Påminelse' + ' (' + invoiceNumbers.reminder +')'" value="reminder">
            <span style="float: left">Påminelse</span>
            <span style="float: right; color: #8492a6; font-size: 13px">({{ invoiceNumbers.reminder }})</span>
        </el-option>
        <el-option :label="'Kreditfaktura' + ' (' + invoiceNumbers.credited +')'" value="credited">
            <span style="float: left">Kreditfaktura</span>
            <span style="float: right; color: #8492a6; font-size: 13px">({{ invoiceNumbers.credited }})</span>
        </el-option>
    </el-select>
</template>
<script>
import queryString from 'query-string';

export default {
    computed: {
        selectedType() {
            return this.$store.state.economy.selectedType;
        },
        invoiceNumbers() {
            return this.$store.state.economy.invoicesNumbersByType;
        },
    },

    created() {
        const { type } = queryString.parse(window.location.search);
        this.$store.commit('economy/updateSelectedType', type);
    },

    methods: {
        selectType(type) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.type = type;
            this.$router.replace({ query }).catch(() => {});
            this.$store.commit('economy/updateSelectedType', type);
            this.$emit('change');
        },
    },
};
</script>
